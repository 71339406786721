
















import { mixins } from 'nuxt-property-decorator'
import changeWebsiteTipMixin from '~/components/layouts/default/common/change-website-tip-mixin'

export default class ChangeSiteTip extends mixins(changeWebsiteTipMixin) {
  changeWebsite() {
    if (this.klook.websiteConfig.website === 'www.klook.com') {
      this.changeCnWebsite()
    } else {
      window.location.href = this.$href('/edit_profile?residence=show')
    }
  }
}
