


















import { Component, Getter, State } from 'nuxt-property-decorator'
import KlkTravellerFooter from '@klook/klk-traveller-footer'
import '@klook/klk-traveller-footer/esm/klkTravellerDesktopFooter.css'
import FooterBase from '@/components/layouts/default/base/footer-base'

@Component({
  components: {
    KlkTravellerFooter
  }
})
export default class DesktopFooter extends FooterBase {
  @State auth!: {
    user: Data.User
  }

  @Getter isGuestCheckout!: boolean

  get email() {
    return this.auth.user?.email
  }

  get isSubscribeVisible() {
    return !this.isGuestCheckout && this.klook.language !== 'ja'
  }

  get isPartnerLinkVisible() {
    return this.getPartnerWebConfig('footer_parter_with_klook')
  }

  get paymentMethods() {
    if (this.isGuestCheckout) {
      return ['newVisa', 'newMaster', 'newAE']
    }
  }
}
