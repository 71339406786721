




















import { Component, State, mixins, Getter } from 'nuxt-property-decorator'
import LayoutControl from '../base/layout-control'
import Header from './header'
import Footer from './footer/index.vue'
import changeWebsiteTip from '~/components/layouts/default/desktop/change-website-tip.vue'
import { genCommonHead } from '~/assets/scripts/head'
import { initAffiliatePartner, initMasterCardParams } from '~/assets/scripts/affiliatePartner'
import { getPageSpm2meta } from '~/components/experience/app-download/const.ts'

@Component({
  components: {
    ExpAppDownload: () => import('~/components/experience/app-download/index.vue'),
    Header,
    Footer,
    changeWebsiteTip
  }
})
export default class LayoutDefault extends mixins(LayoutControl) {
  @State klook!: Data.Klook
  @Getter isGuestCheckout!: boolean

  head() {
    const commonHead = genCommonHead(this)
    return commonHead
  }

  get calcAppDownloadData() {
    if (this.$isServer) { return {} }
    const pageSpm = getPageSpm2meta(this.$route.meta) || (document?.querySelector('body')?.getAttribute('data-spm-page') || '').split('?')[0]
    const pageKey = 'desktop-activity-page'
    return {
      pageSpm,
      pageKey
    }
  }

  mounted() {
    initAffiliatePartner(this)
    initMasterCardParams(this)
  }

  get langData() {
    return {
      select_country_region: this.$t('select_country_region'),
      explore_destinations: this.$t('explore.destinations'),
      scroll_to_viewmore: this.$t('scroll.to.viewmore'),
      desktop_index_hotdest: this.$t('desktop.index.hotdest')
    }
  }

  get secondaryNavData() {
    return this.klook.secondaryNav
  }

  get showSecondaryNav() {
    return !this.hideSecondaryNav && this.klook.utilConfig.showSecondaryNav
  }
}
