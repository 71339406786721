





























import { Component } from 'nuxt-property-decorator'
import KlkTravellerHeader from '@klook/klk-traveller-header'
import '@klook/klk-traveller-header/esm/klkTravellerDesktopHeader.css'
import '@klook/klk-traveller-search-recommendations/esm/index.css'
import HeaderBase from '~/components/layouts/default/common/header-base'
import { suggestCurrencyByLanguage } from '~/share/data/currency'
import eventBus from '~/pages/experience/pay/common/event-bus'
import { getRenderLanguages } from '~/share/data/language'

@Component({
  components: {
    KlkTravellerHeader
  }
})
export default class Header extends HeaderBase {
  get logo() {
    const cobrand = this.klook.affiliateConf?.cobrand
    return cobrand && {
      url: cobrand.logo_url,
      height: cobrand?.cobrand_height_web
    }
  }

  get isSimpleSearch() {
    return this.siteConfig.util_name
  }

  get currencies() {
    return suggestCurrencyByLanguage(this.klook.language, this.klook.websiteConfig?.website)
  }

  get renderLanguages() {
    return getRenderLanguages(this.klook.websiteConfig?.website, this.klook.supportLanguages)
  }

  get isChangeSiteTipVisible() {
    return this.siteConfig.change_site_tip
  }

  get isDownloadAppVisible() {
    return this.siteConfig.downland_bar
  }

  beforeMount() {
    window.$sentry = this.$sentry
    window.Cookies = this.$cookies
    window.$axios = this.$axios
    window.tracker = {
      gtm: {
        sendGTMCustomEvent: this.$sendGTMCustomEvent.bind(this)
      },
      sendMixpanel: () => {},
      inhouse: this.$inhouse
    }
  }

  mounted() {
    eventBus.$on('refresh-shopping-cart', this.refreshShoppingCart)
  }

  beforeDestroy() {
    eventBus.$off('refresh-shopping-cart')
  }

  refreshShoppingCart() {
    const travellerHeader = this.$refs.travellerHeader as any
    if (travellerHeader) {
      travellerHeader.updateShoppingCart()
    }
  }
}
